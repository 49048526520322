.footer {
  background-color:#15100f;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  border-top: 1px solid #e7e7e7;
  font-family: "Lato", sans-serif;
  font-weight: 100;
  color: white;
 
}
.footer-part h2{
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: uppercase;
  color:#96969d;
}
.footer-part a{
   
  transition: color 0.3s ease;
}
.footer-part a:hover{
  color: #96969d;
}

.firstLine{
  margin-bottom: 15px;
 
}
.footer-part{
  margin-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
}
.footer-part a{
  text-decoration: none;
  color: white;
  font-weight: 300;
}
.footerDown, .footerDown a{
  text-decoration: none;
  color: #96969d;

}

.firstLine,
.secondLine,
.horarioBar .hours,
.horario-martesCerrado{
  display: flex;
}
.hours{
  display: flex;
  flex-direction: column;
}
.horarioRB{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
}

.footer-part-horario-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  margin-bottom: 20px;

}

.day{
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: left;
  color:#96969d;
}
.day-name{
  font-weight: 800;
  font-size: 18px;
  margin-right: 15px;
  margin-bottom: 10px;
  text-align: left;
}
.hours-text{
  font-weight: 300;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.footerDown{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
  border-top: 1px solid #96969d;
  padding-top: 2px;
}
.explotaciones{
  border-right: 1px solid #96969d;
  padding-right: 5px;
}
@media (max-width: 768px) {
  .footer{
    padding: 20px;
  }
}